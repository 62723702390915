import * as React from "react"
import {
  ChakraProvider,
  Box,
  Grid,
} from "@chakra-ui/react"
import "@fontsource/comic-neue"
import theme from './theme'
import {Content} from "./Content";
import {MemeModal} from "./MemeModal";

export const App = () => (
  <ChakraProvider theme={theme}>
      <MemeModal/>
    <Box width={"100%"} textAlign="center" fontSize="xl" backgroundColor={"yellow"} opacity={1} background={"linear-gradient(yellow 2px, transparent 2px), linear-gradient(90deg, yellow 2px, transparent 2px), linear-gradient(yellow 1px, transparent 1px), linear-gradient(90deg, yellow 1px, #FFE20A 1px);"} backgroundSize={"50px 50px, 50px 50px, 10px 10px, 10px 10px"} backgroundPosition={"-2px -2px, -2px -2px, -1px -1px, -1px -1px"}>
      <Grid minH="100vh" p={3} style={{
        backgroundImage:
            "url(/bg0-3.png)"
      }}>
        <Content />
      </Grid>
    </Box>
    <Box textAlign="center" fontSize="xl" background-color={"yellow"} background={"linear-gradient(135deg, yellow 25%, transparent 25%), linear-gradient(225deg, yellow 25%, transparent 25%), linear-gradient(45deg, yellow 25%, transparent 25%), linear-gradient(315deg, yellow 25%, #FFE20A 25%)"} backgroundPosition={"10px 0, 10px 0, 0 0, 0 0"} backgroundSize={"10px 10px"} backgroundRepeat={"repeat"}>
      <Grid minH="100vh" p={3}  style={{
        backgroundImage:
            "url(/bg1-2.png)"
      }}>
        <Content />
      </Grid>
    </Box>
    <Box textAlign="center" fontSize="xl" backgroundColor={"yellow"} backgroundImage={"repeating-radial-gradient( circle at 0 0, transparent 0, yellow 10px ), repeating-linear-gradient( #FFE20A55, #FFE20A );"}>
      <Grid minH="100vh" p={3}  style={{
        backgroundImage:
            "url(/bg2-2.png)"
      }}>
        <Content />
      </Grid>
    </Box>
    <Box textAlign="center" fontSize="xl" backgroundColor={"yellow"} backgroundImage={"radial-gradient(#FFE20A 2px, yellow 0.5px);"} backgroundSize={"10px 10px"}>
      <Grid minH="100vh" p={3}>
        <Box h={"30vmin"}>
          <img src={"meme.png"} alt={"funny meme"} />
        </Box>
      </Grid>
    </Box>
  </ChakraProvider>
)
