import {Logo} from "./Logo";
import {Box, Button, Heading, HStack, VStack} from "@chakra-ui/react";
import * as React from "react";

export const Content = () => {
    return (
        <VStack spacing={8}>
            <Logo h="40vmin" pointerEvents="none" />
            <Box h={"2.5vmin"} />
            <Box>
                <VStack spacing={8}>
                        <Heading textColor={"black"} bg={"#FFFFFF95"}>Reflector JOE ($rJOE)</Heading>
                        <Heading size={"md"} bg={"#FFFFFF95"} textColor={"black"}>This is the New World JOErder. Expect the JOE Standard soon. In the normies world there's the FED printing the dying USD, in the real world you have REFLECTOR JOE printing you more JOE simply by holding.</Heading>
                        <Heading size={"md"} bg={"#FFFFFF95"} textColor={"black"}>5% tax ➡️ 2% to buy JOE ➕ 2% for the team and marketing ➕ 1% for LP</Heading>
                        <Heading size={"2xl"} bg={"#FFFFFF95"} textColor={"black"}>BUY REFLECTOR JOE, GET EVEN MORE EXPOSURE TO JOE.</Heading>
                        <HStack>
                            <Button size={"3xl"}
                                    _hover={{bg: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(251,7,217,1) 10%, rgba(186,12,248,1) 20%, rgba(95,21,242,1) 30%, rgba(28,127,238,1) 40%, rgba(47,201,226,1) 50%, rgba(63,218,216,1) 60%, rgba(79,220,74,1) 70%, rgba(208,222,33,1) 80%, rgba(255,154,0,1) 90%, rgba(255,0,0,1) 100%)"}}
                                    height={"100px"}
                                    width={{ base: "100px", sm: "130px", md: "200px" }}
                                    bg={"linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}>
                                Uniswap
                            </Button>
                            <Button size={"3xl"}
                                    _hover={{bg: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(251,7,217,1) 10%, rgba(186,12,248,1) 20%, rgba(95,21,242,1) 30%, rgba(28,127,238,1) 40%, rgba(47,201,226,1) 50%, rgba(63,218,216,1) 60%, rgba(79,220,74,1) 70%, rgba(208,222,33,1) 80%, rgba(255,154,0,1) 90%, rgba(255,0,0,1) 100%)"}}
                                    height={"100px"}
                                    width={{ base: "100px", sm: "130px", md: "200px" }}
                                    bg={"linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}>
                                Dextools
                            </Button>
                            <Button size={"3xl"}
                                    _hover={{bg: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(251,7,217,1) 10%, rgba(186,12,248,1) 20%, rgba(95,21,242,1) 30%, rgba(28,127,238,1) 40%, rgba(47,201,226,1) 50%, rgba(63,218,216,1) 60%, rgba(79,220,74,1) 70%, rgba(208,222,33,1) 80%, rgba(255,154,0,1) 90%, rgba(255,0,0,1) 100%)"}}
                                    height={"100px"}
                                    width={{ base: "100px", sm: "130px", md: "200px" }}
                                    bg={"linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)"}>
                                Twitter
                            </Button>
                        </HStack>
                        <Box h={"5vmin"} />
                        <Heading size={"md"} bg={"#FFFFFF90"} textColor={"black"}>Yellow is psychologically the happiest color and has proven to energize people and relieve depression. Yellow stimulates mental processes, and the nervous system activates memory and encourages communication.</Heading>
                    </VStack>
            </Box>
        </VStack>
    )
}