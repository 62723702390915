import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react'
import React from "react";

export const MemeModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    React.useEffect(() => {
        onOpen();
    }, [onOpen]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={"yellow"}>
                    <ModalCloseButton />
                    <ModalBody>
                        <img src={"meme2.jpg"} alt={"a fantastic meme"} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}